import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import { ChevronIcon } from '../../../images/icons/chevron';
import { colors, screens } from '../../../styles/constants';
import messages from '../messages';
import {
  BUS_COMPANY_LINKS,
  CARPOOLING_COMPANY_LINKS,
  COMPARATOR_LINKS,
  Container,
  ESSENTIAL_LINKS,
  FREQUENT_DESTINATION_LINKS,
  HELP_LINKS,
  LEGAL_LINKS,
  Links,
  StyledLink,
  TICTACTRIP_LINKS,
  TRAIN_COMPANY_LINKS,
  Wrapper,
} from './common';
import { MobileAppLinks } from './End';
import TrustBox from './TrustBox';

const StyledWrapper = styled(Wrapper)`
  ${tw` lg:flex lg:flex-col lg:justify-between lg:flex-row`}
  display: grid;
  gap: var(--size-medium2);

  @media (min-width: ${screens.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TrustBoxAppLinksWrapper = styled.div`
  ${tw`flex flex-col justify-center hidden lg:flex`}
  gap: var(--size-medium2)
`;

const TrustBoxStyle = css`
  ${tw`flex-row`}
  gap: var(--size-small);
`;

type UsefulLinksProps = {
  isReduced: boolean;
  areLinksObfuscated: boolean;
  isCdiscount: boolean;
};

const UsefulLinks = (props: UsefulLinksProps) => {
  const { areLinksObfuscated, isCdiscount, isReduced } = props;

  return (
    <Container>
      <StyledWrapper>
        <EssentialLinks
          areLinksObfuscated={areLinksObfuscated}
          isCdiscount={isCdiscount}
          openInNewWindow={isReduced}
        />
        {isReduced === false && (
          <TictactripLinks
            areLinksObfuscated={areLinksObfuscated}
            isCdiscount={isCdiscount}
            openInNewWindow={isReduced}
          />
        )}
        <HelpLinks
          areLinksObfuscated={areLinksObfuscated}
          isCdiscount={isCdiscount}
          openInNewWindow={isReduced}
        />
        {isReduced === false && (
          <OtherLinks
            areLinksObfuscated={areLinksObfuscated}
            isCdiscount={isCdiscount}
            openInNewWindow={isReduced}
          />
        )}
        {isReduced === true && (
          <TrustBoxAppLinksWrapper>
            <TrustBox customStyle={TrustBoxStyle} />
            {isCdiscount === false && (
              <MobileAppLinks areLinksObfuscated={areLinksObfuscated} />
            )}
          </TrustBoxAppLinksWrapper>
        )}
      </StyledWrapper>
    </Container>
  );
};

type LinkListProps = {
  data: Links;
  openInNewWindow: boolean;
  linkCustomStyle?: FlattenSimpleInterpolation;
  areLinksObfuscated: boolean;
  isCdiscount: boolean;
};

const LinkList = (props: LinkListProps) => {
  const {
    areLinksObfuscated,
    data,
    isCdiscount,
    linkCustomStyle,
    openInNewWindow,
  } = props;

  return (
    <List>
      {data.map((link) =>
        link.isHiddenForCdiscount && isCdiscount ? null : (
          <li key={link.name}>
            <StyledLink
              customStyle={linkCustomStyle}
              href={link.href}
              isObfuscated={link.isObfuscated || areLinksObfuscated}
              target={openInNewWindow ? '_blank' : undefined}
            >
              {link.children}
            </StyledLink>
          </li>
        ),
      )}
    </List>
  );
};

const Title = styled.h3`
  ${tw`m-0 p-0 font-kyrial text-title-large leading-title-large`}
`;

const List = styled.ul`
  ${tw`p-0 m-0 mt-medium`}

  li {
    list-style: none;
  }

  li:not(:last-child) {
    ${tw`mb-medium`}
  }
`;

type LinksProps = {
  openInNewWindow: boolean;
  areLinksObfuscated: boolean;
  isCdiscount: boolean;
};

const EssentialLinks = (props: LinksProps) => {
  const { areLinksObfuscated, isCdiscount, openInNewWindow } = props;

  return (
    <div>
      <Title>
        <FormattedMessage {...messages.theEssentials} />
      </Title>

      <LinkList
        areLinksObfuscated={areLinksObfuscated}
        data={ESSENTIAL_LINKS}
        isCdiscount={isCdiscount}
        openInNewWindow={openInNewWindow}
      />
    </div>
  );
};

const TictactripLinks = (props: LinksProps) => {
  const { areLinksObfuscated, isCdiscount, openInNewWindow } = props;

  return (
    <div>
      <Title>Tictactrip</Title>

      <LinkList
        areLinksObfuscated={areLinksObfuscated}
        data={TICTACTRIP_LINKS}
        isCdiscount={isCdiscount}
        openInNewWindow={openInNewWindow}
      />
    </div>
  );
};

const HelpLinksContainer = styled.div`
  order: 1;

  @media (min-width: ${screens.md}) {
    order: 0;
  }
`;

const LegalLinkStyle = css`
  ${tw`lg:hidden`}
`;

const HelpLinks = (props: LinksProps) => {
  const { areLinksObfuscated, isCdiscount, openInNewWindow } = props;
  return (
    <HelpLinksContainer>
      <Title>
        <FormattedMessage {...messages.help} />
      </Title>
      <LinkList
        areLinksObfuscated={areLinksObfuscated}
        data={HELP_LINKS}
        isCdiscount={isCdiscount}
        openInNewWindow={openInNewWindow}
      />
      <LinkList
        areLinksObfuscated={areLinksObfuscated}
        data={LEGAL_LINKS}
        isCdiscount={isCdiscount}
        linkCustomStyle={LegalLinkStyle}
        openInNewWindow={openInNewWindow}
      />
    </HelpLinksContainer>
  );
};

const AccordionHeader = styled.div`
  ${tw`cursor-pointer flex justify-between items-center`}
  gap: var(--size-medium)
`;

const AccordionContent = styled.div``;

const AccordionTitle = styled(Title)`
  ${tw`text-p-xsmall leading-p-xsmall`}
`;

type AccordionProps = {
  children?: React.ReactNode;
  title: React.ReactNode;
};

const Accordion = (props: AccordionProps) => {
  const { children, title } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <AccordionHeader onClick={() => setIsOpen((isOpen) => !isOpen)}>
        <AccordionTitle>{title}</AccordionTitle>

        <ChevronIcon
          color={colors['secondary-400']}
          customStyle={css`
            transform: rotate(${isOpen ? '90deg' : '270deg'});
          `}
        />
      </AccordionHeader>

      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </div>
  );
};

const OtherLinksContainer = styled.div<OtherLinksProps>`
  ${tw`flex flex-col`}
  gap: var(--size-medium);

  ${({ customStyle }) => customStyle}
`;

type OtherLinksProps = {
  customStyle?: FlattenSimpleInterpolation;
  openInNewWindow: boolean;
  areLinksObfuscated: boolean;
  isCdiscount: boolean;
};

const OtherLinks = (props: OtherLinksProps) => {
  return (
    <OtherLinksContainer {...props}>
      <Accordion
        title={<FormattedMessage {...messages.frequentDestinations} />}
      >
        <LinkList
          areLinksObfuscated={props.areLinksObfuscated}
          data={FREQUENT_DESTINATION_LINKS}
          isCdiscount={props.isCdiscount}
          openInNewWindow={props.openInNewWindow}
        />
      </Accordion>

      <Accordion title={<FormattedMessage {...messages.trainCompanies} />}>
        <LinkList
          areLinksObfuscated={props.areLinksObfuscated}
          data={TRAIN_COMPANY_LINKS}
          isCdiscount={props.isCdiscount}
          openInNewWindow={props.openInNewWindow}
        />
      </Accordion>

      <Accordion title={<FormattedMessage {...messages.busCompanies} />}>
        <LinkList
          areLinksObfuscated={props.areLinksObfuscated}
          data={BUS_COMPANY_LINKS}
          isCdiscount={props.isCdiscount}
          openInNewWindow={props.openInNewWindow}
        />
      </Accordion>

      <Accordion title={<FormattedMessage {...messages.carpoolingCompanies} />}>
        <LinkList
          areLinksObfuscated={props.areLinksObfuscated}
          data={CARPOOLING_COMPANY_LINKS}
          isCdiscount={props.isCdiscount}
          openInNewWindow={props.openInNewWindow}
        />
      </Accordion>

      <Accordion title={<FormattedMessage {...messages.transportComparator} />}>
        <LinkList
          areLinksObfuscated={props.areLinksObfuscated}
          data={COMPARATOR_LINKS}
          isCdiscount={props.isCdiscount}
          openInNewWindow={props.openInNewWindow}
        />
      </Accordion>
    </OtherLinksContainer>
  );
};

export default UsefulLinks;
