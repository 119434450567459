import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { getCurrentRootPage } from '../../helpers';
import { useFooter } from './footer.hook';
import End from './View/End';
import Labels from './View/Labels';
import SocialNetworks from './View/SocialNetworks';
import UsefulLinks from './View/UsefulLinks';
import Welcome from './View/Welcome';

const Container = styled.footer<{
  isHiddenOnMobile: boolean;
  isCdiscount: boolean;
}>`
  ${tw`flex flex-col bg-white`}
  gap: var(--size-medium2);
  ${({ isHiddenOnMobile }) => isHiddenOnMobile && tw`hidden lg:flex`}
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${({ isCdiscount }) =>
    isCdiscount
      ? 'padding-top: calc(var(--size-huge))'
      : 'padding-top: calc(var(--size-huge2) + var(--size-medium2))'}
`;

const LabelsStyle = css`
  ${tw`lg:hidden`}
`;

const Footer = () => {
  const {
    areLinksObfuscated,
    isCdiscount,
    isHidden,
    isHiddenOnMobile,
    isReduced,
  } = useFooter({
    userAgent: navigator.userAgent,
    currentPage: getCurrentRootPage(window.location.pathname),
  });

  if (isHidden) {
    return null;
  }

  return (
    <Container isCdiscount={isCdiscount} isHiddenOnMobile={isHiddenOnMobile}>
      <SocialNetworks
        areLinksObfuscated={areLinksObfuscated}
        isReduced={isReduced}
      />
      <Welcome isCdiscount={isCdiscount} isReduced={isReduced} />
      <UsefulLinks
        areLinksObfuscated={areLinksObfuscated}
        isCdiscount={isCdiscount}
        isReduced={isReduced}
      />
      <Labels customStyle={LabelsStyle} isReduced={isReduced} />
      <End
        areLinksObfuscated={areLinksObfuscated}
        isCdiscount={isCdiscount}
        isReduced={isReduced}
      />
    </Container>
  );
};

export default Footer;
