import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import sncfLogo from '../../../images/providers/logo_sncf.svg';
import { screens } from '../../../styles/constants';
import messages from '../messages';
import { Container, Wrapper } from './common';
import Labels from './Labels';
import Newsletter from './Newsletter';

const Title = styled.h2`
  ${tw`m-0 p-0 font-kyrial text-title-xxlarge leading-title-xxlarge mb-big md:mb-medium`}
`;

const P = styled.p`
  ${tw`text-p-small leading-p-small`}
`;

const NewsletterStyle = css`
  ${tw`hidden lg:block `}
  max-width: 40%;
`;

const SncfImage = styled.img`
  filter: grayscale(100%);
  width: 32px;
`;

const Certification = styled(P)`
  ${tw`my-small flex flex-wrap`}
  column-gap: var(--size-small)
`;

const Wrapper2 = styled(Wrapper)`
  ${tw`flex flex-col mb-medium`}
  gap:var(--size-small)
`;

const Row = styled.div`
  ${tw`flex justify-between items-center`}
`;

const LabelsStyle = css`
  ${tw`hidden md:hidden`}
  @media (min-width: ${screens.lg}) {
    display: grid;
  }
`;

type WelcomeProps = {
  isReduced: boolean;
  isCdiscount: boolean;
};

const Welcome = (props: WelcomeProps) => {
  const { isCdiscount, isReduced } = props;

  return (
    <Container>
      <Wrapper2>
        <Row>
          <Certification>
            <FormattedMessage {...messages.agenceAgreeeSNCF} />
            <SncfImage alt="SNCF logo" src={sncfLogo} />
          </Certification>

          {isReduced === false && isCdiscount === false && (
            <Newsletter customStyle={NewsletterStyle} />
          )}
        </Row>

        <Row style={{ alignItems: 'end' }}>
          <div>
            <Title>
              <FormattedMessage {...messages.welcome} />
            </Title>

            <P>
              <FormattedMessage {...messages.lowCarbonTransportation} />
            </P>

            <P>
              <FormattedMessage {...messages.moreDestination} />
            </P>
          </div>

          {isReduced === false || isCdiscount ? (
            <Labels customStyle={LabelsStyle} isReduced={false} />
          ) : (
            <Newsletter customStyle={NewsletterStyle} />
          )}
        </Row>
      </Wrapper2>
    </Container>
  );
};

export default Welcome;
